import Layout from '~/components/layout/Layout';
import type { LoaderFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { fetchStrapi } from '~/lib/server/api.server';
import type { LandingPage, LandingPageBlock } from '@cardo/types';
import type { MetaFunction } from '@remix-run/react';
import { Form, useLoaderData } from '@remix-run/react';
import { Block } from '~/components/blocks/BlockManager';
import {
  generateMetaFromSeoComponent,
  generateOgUrlMetaDescriptor,
} from '~/lib/meta';
import { cn } from '@cardo/lib';
import { generateAppUrl } from '~/lib/utils';
import { IoSearch } from 'react-icons/io5';

export function headers({ loaderHeaders }: { loaderHeaders: Headers }) {
  return {
    'cache-control': loaderHeaders.get('cache-control'),
  };
}

export const cardsPopulate = {
  populate: {
    card: {
      populate: '*',
    },
  },
};

export const tripReportsPopulate = {
  populate: {
    hotel: {
      populate: {
        hotelGroup: {
          fields: ['rewardsProgram'],
        },
        featuredImage: {
          fields: ['url'],
        },
        coverImage: {
          fields: ['url'],
        },
        location: true,
        hotelBrand: {
          populate: ['thumbnail'],
        },
      },
    },
    flightProduct: {
      populate: {
        coverImage: {
          fields: ['url'],
        },
        airline: {
          fields: ['name'],
        },
        fareClass: {
          fields: ['name'],
        },
      },
    },
    influencer: {
      fields: ['id', 'displayName', 'username', 'verified', 'bio'],
      populate: ['avatar'],
    },
    coverImage: true,
  },
};

export const categoriesPopulate = {
  populate: {
    category: {
      populate: '*',
    },
  },
};

export const postPopulate = {
  populate: {
    blog: {
      populate: '*',
    },
  },
};

const homePopulate = {
  seo: {
    populate: '*',
  },
  coverImage: '*',
  blocks: {
    populate: {
      image: {
        populate: true,
      },
      cards: cardsPopulate,
      tripReports: tripReportsPopulate,
      posts: postPopulate,
      creditCard: {
        populate: '*',
      },
      categories: categoriesPopulate,
    },
  },
};

export const loader: LoaderFunction = async () => {
  const res = await fetchStrapi<LandingPage>('/landing-page', {
    populate: homePopulate,
  });
  return json(res.data, {
    headers: {
      'cache-control':
        'max-age=1, s-maxage=604800, stale-while-revalidate=31536000',
    },
  });
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  return [
    ...generateMetaFromSeoComponent(data?.attributes.seo),
    generateOgUrlMetaDescriptor(location),
    {
      tagName: 'link',
      rel: 'canonical',
      href: generateAppUrl(location.pathname),
    },
    { title: 'Cardonomics - Home' },
  ];
};

type HeroProps = {
  heading: string;
  subheading?: string;
};

function Hero({ heading, subheading }: HeroProps) {
  return (
    <div className="flex min-h-[300px] md:min-h-[500px] h-full w-full flex-grow flex-col items-center justify-center py-12 sm:py-20">
      <h1
        className={cn(
          'text-center text-white text-4xl  md:text-5xl lg:text-6xl',
          subheading && 'mb-4',
          !subheading && 'mb-8 md:mb-16',
        )}
      >
        {heading}
      </h1>
      {subheading && (
        <p className="mb-8 w-4/5 text-center sm:w-1/2 sm:text-xl">
          {subheading}
        </p>
      )}
      <Form
        action="/trip-reports/search"
        method="get"
        className="relative flex w-full md:w-2/5"
      >
        <IoSearch
          className={cn(
            'absolute top-3 left-3 text-2xl lg:text-xl xl:text-2xl text-gray-300 cursor-pointer',
          )}
        />
        <input
          name="q"
          type="text"
          placeholder="Where do you want to go?"
          className="w-full pl-10 pr-10 py-3 bg-white border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent shadow-sm"
          required
        />
      </Form>
    </div>
  );
}

export default function Index() {
  const landingPage = useLoaderData<typeof loader>();

  const FULL_WIDTH_COMPONENTS = [
    'client.featured-cards',
    'client.testimonial',
    'client.image-with-wysiwyg-content',
  ];

  return (
    <Layout
      hero={
        landingPage?.attributes.heading ? (
          <Hero
            heading={landingPage?.attributes.heading}
            subheading={landingPage?.attributes.subheading}
          />
        ) : null
      }
      heroContainerClassName="h-fit"
      heroImage={
        landingPage?.attributes?.coverImage?.data?.attributes?.url ?? ''
      }
      wrapMainInLayoutPadding={false}
    >
      {landingPage.attributes.blocks?.map(
        (block: LandingPageBlock, index: number) => {
          const isFullWidth = FULL_WIDTH_COMPONENTS.includes(block.__component);

          if (isFullWidth) {
            return (
              <Block key={`${block.__component}-${index}`} block={block} />
            );
          }

          return (
            <div
              key={`${block.__component}-${index}`}
              className="w-full 2xl:w-4/5 p-8 sm:p-14 mx-auto my-6 md:my-[50px]"
            >
              <Block block={block} />
            </div>
          );
        },
      )}
    </Layout>
  );
}
